/* eslint-disable @typescript-eslint/camelcase */
import { calculatePrice, getVariantIndex, onlyUnique, smoothScrollToElement } from '../utils/utils';
import { navigate } from 'gatsby';
import currency from 'currency.js';
import isEmpty from 'lodash/isEmpty';
import queryString from 'query-string';

export const BRAND_LOADING = 'dashboard/BRAND_LOADING';
export const CONTACT_CALLBACK_SUCCESS = 'dashboard/CONTACT_CALLBACK_SUCCESS';
export const BRAND_UPDATE_AGREEMENT_TERMS = 'dashboard/BRAND_UPDATE_AGREEMENT_TERMS';
export const BRAND_UPDATE_ALL_VARIANTS = 'dashboard/BRAND_UPDATE_ALL_VARIANTS';
export const BRAND_UPDATE_ANNUAL_MILEAGE = 'dashboard/BRAND_UPDATE_ANNUAL_MILEAGE';
export const BRAND_UPDATE_BRAND = 'dashboard/BRAND_UPDATE_BRAND';
export const BRAND_UPDATE_DEALS = 'dashboard/BRAND_UPDATE_DEALS';
export const BRAND_UPDATE_ERROR = 'dashboard/BRAND_UPDATE_ERROR';
export const BRAND_UPDATE_FILTERED_FUEL = 'dashboard/BRAND_UPDATE_FILTERED_FUEL';
export const BRAND_UPDATE_FILTERED_TRIM = 'dashboard/BRAND_UPDATE_FILTERED_TRIM';
export const BRAND_UPDATE_FORM_DATA = 'dashboard/BRAND_UPDATE_FORM_DATA';
export const BRAND_UPDATE_FUEL = 'dashboard/BRAND_UPDATE_FUEL';
export const BRAND_UPDATE_FUEL_TYPES = 'dashboard/BRAND_UPDATE_FUEL_TYPES';
export const BRAND_UPDATE_INITIAL_RENTAL = 'dashboard/BRAND_UPDATE_INITIAL_RENTAL';
export const BRAND_UPDATE_INITIAL_VARIANTS = 'dashboard/BRAND_UPDATE_INITIAL_VARIANTS';
export const BRAND_UPDATE_LEASE_TYPE = 'dashboard/BRAND_UPDATE_LEASE_TYPE';
export const BRAND_UPDATE_LOADING = 'dashboard/BRAND_UPDATE_LOADING';
export const BRAND_UPDATE_PRICE = 'dashboard/BRAND_UPDATE_PRICE';
export const BRAND_UPDATE_TRIM = 'dashboard/BRAND_UPDATE_TRIM';
export const BRAND_UPDATE_VARIANT = 'dashboard/BRAND_UPDATE_VARIANT';
export const BRAND_FORM_ERROR = 'dashboard/BRAND_FORM_ERROR';
export const BRAND_FORM_SUBMIT = 'dashboard/BRAND_FORM_SUBMIT';
export const BRAND_FORM_SUBMITTING = 'dashboard/BRAND_FORM_SUBMITTING';
export const BRAND_STEP = 'dashboard/BRAND_STEP';
export const BRAND_ADDONS_ERROR = 'dashboard/BRAND_ADDONS_ERROR';
export const BRAND_ADDONS_ITEMS = 'dashboard/BRAND_ADDONS_ITEMS';
export const BRAND_ADDONS_LOADING = 'dashboard/BRAND_ADDONS_LOADING';
export const BRAND_ADDONS_OPTIONS = 'dashboard/BRAND_ADDONS_OPTIONS';
export const BRAND_ADDONS_PRICE = 'dashboard/BRAND_ADDONS_PRICE';
export const BRAND_ADDONS_RESET = 'dashboard/BRAND_ADDONS_RESET';
export const BRAND_RESET = 'dashboard/BRAND_RESET';
export const BRAND_VISIBLE_TITLE_HEADER = 'dashboard/BRAND_VISIBLE_TITLE_HEADER';
export const BRAND_UPDATE_PREFERENCES = 'dashboard/BRAND_UPDATE_PREFERENCES';

export function dataLoading(payload) {
  return {
    payload,
    type: BRAND_LOADING,
  };
}

export function updateLeaseType(payload) {
  return {
    payload,
    type: BRAND_UPDATE_LEASE_TYPE,
  };
}

export function updateVariant(payload, isInitial) {
  // If the variant has changed then update the URL to reflect this
  if (typeof window !== 'undefined') {
    const relativePath = location.href.replace(location.origin, '').split('?')[0];
    const oldUrl = location.href.replace(location.origin, '');
    const newUrl = relativePath + '?variant=' + payload.slug;

    if (oldUrl !== newUrl && !isInitial) {
      navigate(newUrl);
    }
  }

  return {
    payload,
    type: BRAND_UPDATE_VARIANT,
  };
}

export function updateAllVariants(payload) {
  return {
    payload,
    type: BRAND_UPDATE_ALL_VARIANTS,
  };
}

// Form inputs
export function updateInitialVariants(payload) {
  return {
    payload,
    type: BRAND_UPDATE_INITIAL_VARIANTS,
  };
}

// Form inputs
export function updateInitialRental(payload) {
  return {
    payload,
    type: BRAND_UPDATE_INITIAL_RENTAL,
  };
}

export function updateAgreementTerms(payload) {
  return {
    payload,
    type: BRAND_UPDATE_AGREEMENT_TERMS,
  };
}

export function updateAnnualMileage(payload) {
  return {
    payload,
    type: BRAND_UPDATE_ANNUAL_MILEAGE,
  };
}

export function setError(payload) {
  return {
    payload,
    type: BRAND_UPDATE_ERROR,
  };
}

export function updateTrim(payload) {
  return {
    payload,
    type: BRAND_UPDATE_TRIM,
  };
}

export function updateFuel(payload) {
  return {
    payload,
    type: BRAND_UPDATE_FUEL,
  };
}

export function updatePrice(payload) {
  return {
    payload,
    type: BRAND_UPDATE_PRICE,
  };
}

export function updateDeals(payload) {
  return {
    payload,
    type: BRAND_UPDATE_DEALS,
  };
}

export function updateBrand(payload) {
  return {
    payload,
    type: BRAND_UPDATE_BRAND,
  };
}

export function updateFuelTypes(payload) {
  return {
    payload,
    type: BRAND_UPDATE_FUEL_TYPES,
  };
}

export function setFormData(field, value) {
  return {
    field,
    value,
    type: BRAND_UPDATE_FORM_DATA,
  };
}

export function setSubmitting(payload) {
  return {
    payload,
    type: BRAND_FORM_SUBMITTING,
  };
}

export function setSubmit(bool, payload) {
  return {
    bool,
    payload,
    type: BRAND_FORM_SUBMIT,
  };
}

export function setSubmitError(payload) {
  return {
    payload,
    type: BRAND_FORM_ERROR,
  };
}

export function setAddonOptions(payload) {
  return {
    payload,
    type: BRAND_ADDONS_OPTIONS,
  };
}

export function setAddonOptionsLoading(payload) {
  return {
    payload,
    type: BRAND_ADDONS_LOADING,
  };
}

export function setAddonOptionsError(payload) {
  return {
    payload,
    type: BRAND_ADDONS_ERROR,
  };
}

export function setAddonValues(payload) {
  return {
    payload,
    type: BRAND_ADDONS_ITEMS,
  };
}

export function resetAddons() {
  return {
    type: BRAND_ADDONS_RESET,
  };
}

export function setVariantLoading(payload) {
  return {
    payload,
    type: BRAND_UPDATE_LOADING,
  };
}

export function setAddonsTotal(payload) {
  return {
    payload,
    type: BRAND_ADDONS_PRICE,
  };
}

export function setActiveStep(payload) {
  return {
    payload,
    type: BRAND_STEP,
  };
}

export function resetData() {
  return {
    type: BRAND_RESET,
  };
}

export function updateInitialValues(initialRental, agreementTerms, leaseType, annualMileage) {
  return {
    initialRental,
    agreementTerms,
    leaseType,
    annualMileage,
    type: BRAND_UPDATE_PREFERENCES,
  };
}

export const handleResetData = () => (dispatch) => {
  dispatch(resetData());
};

export const handleUpdateAddons = (values, leaseType, agreementTerms, initialRental, items) => (
  dispatch
) => {
  // Prices of selected addons
  let addonPrices = [];
  let addonsTotal = 0;

  // Only calculate the price if an addon is selected
  if (!isEmpty(values)) {
    addonPrices = values.map((item) => {
      return leaseType === 'business'
        ? Math.round(item.priceExcVat / (agreementTerms + initialRental - 1))
        : Math.round(item.priceIncVat / (agreementTerms + initialRental - 1));
    });

    // Calculate the running total for the selected addons
    addonsTotal = addonPrices.reduce((a, b) => a + b, 0);
  }

  dispatch(setAddonsTotal(addonsTotal));

  // Update the item options in the case of options changing
  if (items) {
    const itemValues = values.map((item) => {
      const itemPrice =
        leaseType === 'business'
          ? Math.round(item.priceExcVat / (agreementTerms + initialRental - 1))
          : Math.round(item.priceIncVat / (agreementTerms + initialRental - 1));
      return {
        ...item,
        label:
          itemPrice >= 1
            ? `${item.value} (+${currency(itemPrice, {
                precision: 0,
                symbol: '£',
              }).format()} pcm)`
            : `${item.value} - Free`,
      };
    });

    const gOptions = items.map((item) => {
      const options = item.options.map((item) => {
        const itemPrice =
          leaseType === 'business'
            ? Math.round(item.priceExcVat / (agreementTerms + initialRental - 1))
            : Math.round(item.priceIncVat / (agreementTerms + initialRental - 1));
        return {
          value: item.value,
          isPOA: item.isPOA,
          label:
            itemPrice >= 1
              ? `${item.value} - ${currency(itemPrice, {
                  precision: 0,
                  symbol: '£',
                }).format()}pcm`
              : `${item.value} - Free`,
          priceExcVat: item.priceExcVat,
          priceIncVat: item.priceIncVat,
        };
      });

      return {
        label: item.label,
        options,
      };
    });

    dispatch(setAddonValues(itemValues));
    dispatch(setAddonOptions(gOptions));
  } else {
    dispatch(setAddonValues(values));
  }
};

export const handleUpdateFormData = (field, value) => (dispatch) => {
  dispatch(setFormData(field, value));
};

export const handleUpdateLeaseType = (leaseType) => (dispatch) => {
  dispatch(updateLeaseType(leaseType));
};

export const getDeals = (data) => (dispatch) => {
  dispatch(updateDeals(data));
};

export const getAddons = (postId, agreementTerms, initialRental, leaseType) => (dispatch) => {
  dispatch(setAddonOptionsLoading(true));

  fetch(`${process.env.GATSBY_SITE_URL}/wp-json/addons/v1/show`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      ['post_id']: postId,
    }),
  })
    .then((res) => {
      if (res.status === 200) {
        return res.json();
      }

      return dispatch(setAddonOptionsError(true));
    })
    .then((res) => {
      const addonGroups = res.map((item) => {
        // The parent category
        const label = item.label;

        const options = item.options.map((item) => {
          const itemPrice =
            leaseType === 'business'
              ? Math.round(item.priceExcVat / (agreementTerms + initialRental - 1))
              : Math.round(item.priceIncVat / (agreementTerms + initialRental - 1));
          return {
            value: item.value,
            isPOA: item.isPOA,
            label:
              itemPrice >= 1
                ? `${item.value} (+${currency(itemPrice, {
                    precision: 0,
                    symbol: '£',
                  }).format()}pcm)`
                : `${item.value} - Free`,
            priceExcVat: item.priceExcVat,
            priceIncVat: item.priceIncVat,
            parent: label,
          };
        });

        return {
          label: item.label,
          options,
        };
      });

      dispatch(setAddonOptions(addonGroups));
      dispatch(setAddonOptionsLoading(false));
      return;
    })
    .catch(() => {
      dispatch(setAddonOptionsError(true));
      dispatch(setAddonOptionsLoading(false));
    });
};

export const submitQuote = (payload, values) => (dispatch) => {
  dispatch(setSubmitting(true));

  fetch(`${process.env.GATSBY_SITE_URL}/wp-json/wp_mail/v1/enquiry`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  })
    .then((res) => {
      if (res.status === 200) {
        dispatch(setSubmitting(false));
        dispatch(setSubmit(true, values));

        if (typeof window !== 'undefined' && window.gtag) {
          window.gtag('event', 'click', {
            event_category: 'quote',
            event_label: payload['Car'],
          });
        }

        // Scroll to the top to for the view change
        typeof window !== 'undefined' &&
          window.scrollTo({
            top: 350,
            left: 0,
            behavior: 'smooth',
          });

        return;
      }

      return dispatch(setSubmitError(true));
    })
    .catch(() => {
      dispatch(setSubmitError(true));
    });
};

/**
 * @name getVariants
 * @description Gets the initial variants and calculate the initial price and options for the lease builder template. Check for the query string to adjust the item dynamically
 * @param {*} variantData
 * @param {*} brandData
 */
export const getVariants = (variantData, brandData) => (dispatch) => {
  // The preferences saved if they exist
  const savedPreferences =
    typeof window !== 'undefined' &&
    localStorage.getItem('content') &&
    JSON.parse((typeof window !== 'undefined' && localStorage.getItem('content')) || '');

  // If there are no variants then just get the brand data
  if (isEmpty(variantData.edges)) {
    dispatch(updateBrand(brandData));
    dispatch(dataLoading(false));
  }

  // Check for the variant query param and parse it
  const queryParams = queryString.parse(typeof window !== 'undefined' && window.location.search);
  const queryParamVariant = queryParams['variant'];

  // Set the recommended deal if it exists
  const bestDeals =
    (brandData && brandData.acf && brandData.acf.best_deals && brandData.acf.best_deals) || [];

  let activeVariant;
  let targetVariant;

  // Filter the variant data to get the variant from the query param based on the slug if has been set
  if (queryParamVariant) {
    activeVariant = variantData.edges.filter((variant) => variant.node.slug === queryParamVariant);

    // Otherwise see if these is a best deal set and get the first one
  } else if (!isEmpty(bestDeals)) {
    activeVariant = variantData.edges.filter(
      (variant) => variant.node.wordpress_id === bestDeals[0].wordpress_id
    );
  }

  // If it is a valid query param then set the new target variant, otherwise use the first one
  const isQueryParam = queryParamVariant && !isEmpty(activeVariant) ? true : false;

  // If there are best deals available and there is no targeted variant from the query param
  const isBestDeal = !isEmpty(bestDeals) && !isQueryParam && !isEmpty(activeVariant) ? true : false;

  // Set the default variant based on the query param or a deal, otherwise load the first variant as a fallback
  if (isQueryParam || isBestDeal) {
    targetVariant = activeVariant[0];
  } else {
    targetVariant = variantData.edges[0];
  }

  // If the request is valid
  if (targetVariant) {
    // Use the initial variants array if the item is already fitlered
    const trimType = targetVariant.node.post_meta_fields.trim;
    const fuelType = targetVariant.node.post_meta_fields.fuel;

    const agreementTerms = (savedPreferences && savedPreferences.agreementTerms) || 48;
    const annualMileage = (savedPreferences && savedPreferences.annualMileage) || 8000;
    const initialRental = (savedPreferences && savedPreferences.initialRental) || 12;
    const leaseType = (savedPreferences && savedPreferences.leaseType) || 'business';

    dispatch(updateInitialValues(initialRental, agreementTerms, leaseType, annualMileage));

    // Filter the variants based on the trim value
    const filteredTrimType = variantData.edges.filter(
      (variant) => variant.node.post_meta_fields.trim === trimType
    );

    // Filter the varaints based on fuel for the trim
    const filteredFuelType = filteredTrimType.filter(
      (variant) => variant.node.post_meta_fields.fuel
    );

    // Prepare the object to add to state to match Gatsby data format
    const filteredVariants = { edges: filteredFuelType };
    const defaultVariant = targetVariant.node;

    // Calucate the price for the variant
    const prices = calculatePrice(
      leaseType,
      agreementTerms,
      initialRental,
      annualMileage,
      defaultVariant
    );

    // Check for all the fuel types available for all the variants
    const availableFuelTypes = filteredVariants.edges.map(
      (variant) => variant.node.post_meta_fields.fuel
    );

    // Filter down the variants by fuel type
    const filteredVariantsByFuelType = filteredVariants.edges.filter(
      (variant) => variant.node.post_meta_fields.fuel === fuelType
    );

    // All variants after filter has been applied
    const allVariants = { edges: filteredVariantsByFuelType };

    // Filter the duplicate trims to get an array of available trims
    const uniqueFuelTypes = availableFuelTypes.filter(onlyUnique);

    dispatch(updateBrand(brandData));
    dispatch(updateFuelTypes(uniqueFuelTypes));
    dispatch(updatePrice(prices));
    dispatch(updateAllVariants(allVariants));

    // Keep a copy of all variants in state
    dispatch(updateInitialVariants(variantData));

    // Add the default variant, the first item in the array
    dispatch(updateVariant(defaultVariant, true));

    // Set the default trim
    dispatch(updateTrim(targetVariant.node.post_meta_fields.trim));

    // Set the default fuel type
    dispatch(updateFuel(targetVariant.node.post_meta_fields.fuel));

    // Remove the loading state after data is passed from Gatsby props to state
    dispatch(dataLoading(false));
  }
};

export const handleUpdateInitialRental = (value) => (dispatch) => {
  dispatch(updateInitialRental(value));
};

export const handleUpdateAgreementTerms = (value) => (dispatch) => {
  dispatch(updateAgreementTerms(value));
};

export const handleUpdateAnnualMileage = (value) => (dispatch) => {
  dispatch(updateAnnualMileage(value));
};

export const handleUpdateTrim = (value) => (dispatch) => {
  dispatch(updateTrim(value));
};

export const handleUpdateFuel = (value) => (dispatch) => {
  dispatch(updateFuel(value));
};

export const handleActiveStep = (value, isReset = false) => (dispatch) => {
  dispatch(setActiveStep(value));

  // If the action does not reset the state scroll
  if (!isReset) {
    // Scroll back to the form header after render
    smoothScrollToElement('customiseLease');
  }
};

export const handleUpdateVariant = (data) => (dispatch) => {
  dispatch(setVariantLoading(true));

  // Reset the addons for the variant and reset the step number
  dispatch(resetAddons());
  dispatch(handleActiveStep(0, true));

  dispatch(updateVariant(data, false));

  setTimeout(() => {
    dispatch(setVariantLoading(false));
  }, 1000);
};

export const handleFilterVariants = (trimType, fuelType, variants) => (dispatch) => {
  // Reset the addons for the variant and reset the step number
  dispatch(resetAddons());
  dispatch(handleActiveStep(0, true));

  const filteredTrimType = variants.edges.filter(
    (variant) => variant.node.post_meta_fields.trim === trimType
  );

  // Filter the varaints based on fuel for the trim
  let filteredFuelType = filteredTrimType.filter(
    (variant) => variant.node.post_meta_fields.fuel === fuelType
  );

  // If no results exist for the fuel type default to the fuel type for the default variant
  if (isEmpty(filteredFuelType)) {
    // Revert to the filtered trim array
    filteredFuelType = filteredTrimType;

    // Use the default fuel type
    dispatch(updateFuel(filteredFuelType[0].node.post_meta_fields.fuel));
  } else {
    dispatch(updateFuel(fuelType));
  }

  // Prepare the object to add to state to match Gatsby data format
  const filteredVariants = { edges: filteredFuelType };

  // The available fuel types from the trim filtered variants
  const allFuelTypes = filteredTrimType.map((variant) => variant.node.post_meta_fields.fuel);

  // Filter the duplicate trims to get an array of available trims
  const uniqueFuelTypes = allFuelTypes.filter(onlyUnique);

  dispatch(updateTrim(trimType));

  dispatch(updateFuelTypes(uniqueFuelTypes));

  // Update the variants
  dispatch(updateAllVariants(filteredVariants));

  // Update the variant to the first item in the new array
  dispatch(updateVariant(filteredVariants.edges[0].node, false));
};

export const handleBestDeal = (variantId, variants) => (dispatch) => {
  // Reset the addons for the variant and reset the step number
  dispatch(resetAddons());
  dispatch(handleActiveStep(0, true));

  // The selected variant
  const selectedVariant = getVariantIndex(variantId, variants);

  const filteredTrimType = variants.edges.filter(
    (variant) => variant.node.post_meta_fields.trim === selectedVariant.post_meta_fields.trim
  );

  const filteredFuelType = filteredTrimType.filter(
    (variant) => variant.node.post_meta_fields.fuel === selectedVariant.post_meta_fields.fuel
  );

  // Prepare the object to add to state to match Gatsby data format
  const filteredVariants = { edges: filteredFuelType };

  // The available fuel types from the trim filtered variants
  const allFuelTypes = filteredTrimType.map((variant) => variant.node.post_meta_fields.fuel);

  // Filter the duplicate trims to get an array of available trims
  const uniqueFuelTypes = allFuelTypes.filter(onlyUnique);

  // Update all the variants
  dispatch(updateAllVariants(filteredVariants));

  // Update the selected variant based on the selected deal
  dispatch(updateVariant(selectedVariant, false));

  // Update the trim and fuel filters
  dispatch(updateTrim(selectedVariant.post_meta_fields.trim));

  // Update the fuel types based on the trim
  dispatch(updateFuelTypes(uniqueFuelTypes));

  // Update the fuel type
  dispatch(updateFuel(selectedVariant.post_meta_fields.fuel));
};

export const calculateVariantPrice = (
  leaseType,
  agreementTerms,
  initialRental,
  annualMileage,
  variant,
  addonsTotal
) => (dispatch) => {
  if (!variant) {
    return;
  }

  const variantPrice = calculatePrice(
    leaseType,
    agreementTerms,
    initialRental,
    annualMileage,
    variant,
    addonsTotal
  );

  dispatch(updatePrice(variantPrice));

  setTimeout(() => {
    // Save preferences to local storage to persist on page change
    localStorage.setItem(
      'content',
      JSON.stringify({ leaseType, agreementTerms, initialRental, annualMileage })
    );
  }, 1000);
};
