import {
  BRAND_ADDONS_ERROR,
  BRAND_ADDONS_ITEMS,
  BRAND_ADDONS_LOADING,
  BRAND_ADDONS_OPTIONS,
  BRAND_ADDONS_PRICE,
  BRAND_ADDONS_RESET,
  BRAND_FORM_ERROR,
  BRAND_FORM_SUBMIT,
  BRAND_FORM_SUBMITTING,
  BRAND_LOADING,
  BRAND_RESET,
  BRAND_STEP,
  BRAND_UPDATE_AGREEMENT_TERMS,
  BRAND_UPDATE_ALL_VARIANTS,
  BRAND_UPDATE_ANNUAL_MILEAGE,
  BRAND_UPDATE_BRAND,
  BRAND_UPDATE_DEALS,
  BRAND_UPDATE_ERROR,
  BRAND_UPDATE_FILTERED_FUEL,
  BRAND_UPDATE_FILTERED_TRIM,
  BRAND_UPDATE_FORM_DATA,
  BRAND_UPDATE_FUEL,
  BRAND_UPDATE_FUEL_TYPES,
  BRAND_UPDATE_INITIAL_RENTAL,
  BRAND_UPDATE_INITIAL_VARIANTS,
  BRAND_UPDATE_LEASE_TYPE,
  BRAND_UPDATE_LOADING,
  BRAND_UPDATE_PREFERENCES,
  BRAND_UPDATE_PRICE,
  BRAND_UPDATE_TRIM,
  BRAND_UPDATE_VARIANT,
} from '../actions/brand';

const savedPreferences =
  typeof window !== 'undefined' &&
  localStorage.getItem('content') &&
  JSON.parse((typeof window !== 'undefined' && localStorage.getItem('content')) || '');

const initialState = {
  activeStep: 0,
  addonsTotal: 0,
  agreementTerms: (savedPreferences && savedPreferences.agreementTerms) || 48,
  annualMileage: (savedPreferences && savedPreferences.annualMileage) || 8000,
  bestDeals: [],
  brand: {},
  depositPrice: 0,
  formData: {
    deliveryDate: new Date(),
    maintenance: 'No',
    businessName: '',
    email: '',
    forename: '',
    phoneNumber: '',
    surname: '',
    title: 'Mr',
    tradingStyle: 'Limited Company',
    yearsTrading: '',
  },
  fuelType: '',
  fuelTypes: [],
  groupOptions: [],
  initialRental: (savedPreferences && savedPreferences.initialRental) || 12,
  initialVariants: {},
  isErrorAddons: false,
  isLoading: true,
  isError: false,
  isLoadingAddons: false,
  isLoadingVariant: false,
  isSubmit: false,
  isSubmitError: false,
  isSubmitting: false,
  leaseType: (savedPreferences && savedPreferences.leaseType) || 'business',
  monthlyPrice: 0,
  selectedAddons: [],
  selectedVariant: {},
  trim: '',
  variants: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case BRAND_UPDATE_PREFERENCES: {
      return {
        ...state,
        initialRental: action.initialRental,
        annualMileage: action.annualMileage,
        agreementTerms: action.agreementTerms,
        leaseType: action.leaseType,
      };
    }
    case BRAND_RESET: {
      return {
        ...initialState,
      };
    }
    case BRAND_UPDATE_LOADING: {
      return {
        ...state,
        isLoadingVariant: action.payload,
      };
    }
    case BRAND_FORM_ERROR: {
      return {
        ...state,
        isSubmitError: true,
      };
    }
    case BRAND_UPDATE_ERROR: {
      return {
        ...state,
        isError: action.payload,
      };
    }
    case BRAND_ADDONS_PRICE: {
      return {
        ...state,
        addonsTotal: action.payload,
      };
    }
    case BRAND_STEP: {
      return {
        ...state,
        activeStep: action.payload,
      };
    }
    case BRAND_ADDONS_RESET: {
      return {
        ...state,
        addonsTotal: 0,
        groupOptions: [],
        selectedAddons: [],
      };
    }
    case BRAND_ADDONS_ITEMS: {
      return {
        ...state,
        selectedAddons: action.payload,
      };
    }
    case BRAND_ADDONS_ERROR: {
      return {
        ...state,
        isErrorAddons: action.payload,
      };
    }
    case BRAND_ADDONS_LOADING: {
      return {
        ...state,
        isLoadingAddons: action.payload,
      };
    }
    case BRAND_ADDONS_OPTIONS: {
      return {
        ...state,
        groupOptions: action.payload,
      };
    }
    case BRAND_FORM_SUBMITTING: {
      return {
        ...state,
        isSubmitting: action.payload,
      };
    }
    case BRAND_FORM_SUBMIT:
      return {
        ...state,
        isSubmit: action.bool,
        formData: {
          ...state.formData,
          ...action.payload,
        },
      };
    case BRAND_UPDATE_FORM_DATA:
      return {
        ...state,
        formData: {
          ...state.formData,
          [action.field]: action.value,
        },
      };
    case BRAND_UPDATE_FUEL_TYPES:
      return {
        ...state,
        fuelTypes: action.payload,
      };
    case BRAND_UPDATE_BRAND:
      return {
        ...state,
        brand: action.payload,
      };

    case BRAND_UPDATE_DEALS:
      return {
        ...state,
        bestDeals: action.payload,
      };

    case BRAND_UPDATE_PRICE:
      return {
        ...state,
        monthlyPrice: action.payload.monthlyPrice,
        depositPrice: action.payload.depositPrice,
      };
    case BRAND_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };

    case BRAND_UPDATE_TRIM:
      return {
        ...state,
        trim: action.payload,
      };

    case BRAND_UPDATE_FUEL:
      return {
        ...state,
        fuelType: action.payload,
      };

    case BRAND_UPDATE_FILTERED_TRIM:
      return {
        ...state,
        isFilteredTrim: action.payload,
      };

    case BRAND_UPDATE_FILTERED_FUEL:
      return {
        ...state,
        isFilteredFuel: action.payload,
      };

    case BRAND_UPDATE_LEASE_TYPE:
      return {
        ...state,
        leaseType: action.payload,
      };

    case BRAND_UPDATE_ALL_VARIANTS:
      return {
        ...state,
        variants: action.payload,
      };

    case BRAND_UPDATE_VARIANT:
      return {
        ...state,
        selectedVariant: action.payload,
      };

    case BRAND_UPDATE_INITIAL_VARIANTS:
      return {
        ...state,
        initialVariants: action.payload,
      };

    case BRAND_UPDATE_INITIAL_RENTAL:
      return {
        ...state,
        initialRental: action.payload,
      };

    case BRAND_UPDATE_AGREEMENT_TERMS:
      return {
        ...state,
        agreementTerms: action.payload,
      };

    case BRAND_UPDATE_ANNUAL_MILEAGE:
      return {
        ...state,
        annualMileage: action.payload,
      };

    default:
      return state;
  }
};
