export const styleConfig = {
  // Padding
  // ------
  pad: {
    small: 40,
    medium: 40,
    large: 75,
    xlarge: 75,
  },

  // Colours
  // ------
  fonts: {
    header: 'Bai Jamjuree',
    body: 'Open Sans',
  },
  color: {
    bg: {
      white: '#ffffff',
      black: '#000000',
      offwhite: '#F8F8F8',
      darkgrey: '#2B2B2B',
      darkergrey: '#151515',
      gold: '#B58C40',
      lightergrey: '#999999',
      altLightGrey: '#E7E7E7',
    },

    text: {
      white: '#ffffff',
      black: '#000000',
      lightgrey: '#AAAAAA',
      gray: '#7A7A7A',
      darkgrey: '#2B2B2B',
      gold: '#B58C40',
      lightergrey: '#999999',
    },
  },
};

export default null;
