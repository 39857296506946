export const fuelTypes = [
  'All',
  'Petrol/Electric Hybrid',
  'Petrol/PlugIn Electric Hybrid',
  'Diesel/Electric Hybrid',
  'Diesel/PlugIn Electric Hybrid',
  'Electric',
  'Petrol',
  'Diesel',
];
export const fuelTypesCap = [
  { label: 'All', value: '' },
  { label: 'Petrol/Electric Hybrid', value: 'H' },
  { label: 'Petrol/PlugIn Electric Hybrid', value: 'X' },
  { label: 'Diesel/Electric Hybrid', value: 'Y' },
  { label: 'Diesel/PlugIn Electric Hybrid', value: 'Z' },
  { label: 'Electric', value: 'E' },
  { label: 'Petrol', value: 'P' },
  { label: 'Diesel', value: 'D' },
];
export const transmissionTypes = ['All', 'Automatic', 'Manual'];
export const leaseTypes = ['personal', 'business'];
export const leaseTypeButtons = [
  {
    value: 'business',
    label: 'Business',
  },
  {
    value: 'personal',
    label: 'Personal',
  },
];
export const searchResultsPerPage = 24;
export const searchResultsPerPageOptions = [24, 48, 72, 96, 120];
export const searchSortOptions = ['Ascending', 'Descending', 'Price: Highest', 'Price: Lowest'];
export const searchPreSelectedBrands = [
  { label: 'Audi', value: 'audi' },
  { label: 'BMW', value: 'bmw' },
  { label: 'Jaguar', value: 'jaguar' },
  { label: 'Land Rover', value: 'land-rover' },
  { label: 'Mercedes-Benz', value: 'mercedes-benz' },
  { label: 'Porsche', value: 'porsche' },
];

export const pricingMarks = [
  {
    value: 150,
    label: '< £150',
  },
  {
    value: 300,
  },
  {
    value: 450,
  },
  {
    value: 600,
  },
  {
    value: 750,
  },
  {
    value: 900,
  },
  {
    value: 1050,
  },
  {
    value: 1200,
  },
  {
    value: 1350,
  },
  {
    value: 1500,
    label: '£1500 >',
  },
];

export const initialRentalMarks = [
  {
    value: 3,
    label: '3',
  },
  {
    value: 6,
    label: '6',
  },
  {
    value: 9,
    label: '9',
  },
  {
    value: 12,
    label: '12',
  },
];

export const agreementTermsMarks = [
  {
    value: 24,
    label: '24',
  },
  {
    value: 36,
    label: '36',
  },
  {
    value: 48,
    label: '48',
  },
];

export const annualMileageMarks = [
  {
    value: 8000,
    label: '8000',
  },
  {
    value: 9000,
  },
  {
    value: 10000,
  },
  {
    value: 11000,
  },
  {
    value: 12000,
  },
  {
    value: 13000,
  },
  {
    value: 14000,
  },
  {
    value: 15000,
  },
  {
    value: 16000,
  },
  {
    value: 17000,
  },
  {
    value: 18000,
  },
  {
    value: 19000,
  },
  {
    value: 20000,
    label: '20000',
  },
];

export const bodyTypes = [
  {
    label: 'Convertible',
    value: 'Convertible',
  },
  {
    label: 'SUV',
    value: 'SUV',
  },
  {
    label: 'Coupe',
    value: 'Coupe',
  },
  {
    label: 'Roadster',
    value: 'Roadster',
  },
  {
    label: 'Estate',
    value: 'Estate',
  },
  {
    label: 'Saloon',
    value: 'Saloon',
  },
  {
    label: 'Hatchback',
    value: 'Hatchback',
  },
];

export const tooltipValues = {
  agreementTerms:
    'The Contract Hire Term is the length of the lease agreement with a lender. Please contact us for special requests, like a 60-month rental or something else you need.',
  annualMileage:
    'Accurately estimating your annual mileage over the agreement term keeps the monthly rental as low as possible. Please contact us for special requests, like higher mileages or something else you need.',
  bestTimeToCall: 'Please confirm, when is the best time of day to contact you?',
  brands:
    "The standard search setting search includes all the brands we lease. To fine-tune your results, exclude the manufacturers you don't need by clicking the corresponding 'x' and search again",
  businessName: 'Please confirm the name of the business.',
  contactPreferences: 'Please confirm how you would like us to contact you.',
  deliveryDate:
    "Please confirm when you'd prefer to take delivery of the new car. Some deals and offers are only available for a limited period or while stocks last.",
  email:
    "Please confirm your email address. We'll need your email address to send a detailed and written quotation that matches your requirements.",
  forename: 'Please confirm your forename.',
  fuelType:
    "Choose the type of fuel you need. Please get in touch if you aren't sure or prefer to chat things over.",
  initialRental:
    'The initial rental forms part of the total agreement cost, it is calculated from a multiple of the monthly rental.',
  leaseType: 'In this section you can change the deal to a Business Lease or a Personal Lease.',
  maintenance:
    "A Maintenance package offers peace of mind. It's an additional monthly payment to comprehensively cover the financial cost of scheduled maintenance (subject to the terms of the agreement). Choose 'Yes' to find out more.'",
  message: 'Please tell us about a special request or information you think would be helpful.',
  phoneNumber:
    'Please tell us your phone number. We might need to discuss the specification or the delivery date you preferred.',
  surname: 'Please confirm your surname.',
  title: 'Please select a title.',
  tradingStyle: 'Please select the type of business.',
  trimType:
    "Choose which trim level you need. Please get in touch if you aren't sure or prefer to chat things over.",
  variant:
    "Here are the available variants from your choice of trim and fuel type. If you don't see the perfect match, please get in touch.",
  yearsTrading:
    "Please confirm how long the business has been trading. If the business has been trading for less than 12 months we'll usually get in touch to discuss the options.",
  transmission: 'Filter the results with your favourite type of gearbox.',
  bodyStyle:
    "Filter the results with your favourite body types. If you want an SUV, we recommend including both 'estate' and 'station wagon' in your search.",
  pricing:
    'Each marker equals £150 per month. The minimum setting is £150 per month or less, and the maximum is for £1,200 per month or more. We recommend starting with a broad price range, then fine-tune the price by narrowing the minimum/maximum monthly rental, and search again.',
};

export default null;
