import { NOTIFICATIONS_ACTIVE } from '../actions/notifications';

const initialState = {
  isActive: false,
  message: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case NOTIFICATIONS_ACTIVE: {
      return {
        ...state,
        isActive: action.isActive,
        message: action.message,
      };
    }

    default:
      return state;
  }
};
