import { combineReducers } from 'redux';

// Reducers
import brand from '../reducers/brand';
import specialOffers from '../reducers/specialOffers';
import notifications from '../reducers/notifications';

const rootReducer = combineReducers({
  notifications,
  brand,
  specialOffers,
});

export default rootReducer;
