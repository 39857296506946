// Styles
import React, { useEffect } from 'react';
import { ThemeProvider } from '@material-ui/styles';

// Material 4 theme configuration
import { theme } from '../../config/theme';
import { setDefaultPreferences } from '../utils/utils';

// Set the default lease option local storage object if it doesn't exist
const App: React.FunctionComponent = ({ children }) => {
  useEffect(() => {
    setDefaultPreferences();
  }, []);

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default App;
