export const NOTIFICATIONS_ACTIVE = 'dashboard/NOTIFICATIONS_ACTIVE';

export function setNotification(isActive, message) {
  return {
    isActive,
    message,
    type: NOTIFICATIONS_ACTIVE,
  };
}

export const handleDisplayNotification = (isActive, message) => (dispatch) => {
  dispatch(setNotification(isActive, message));
};
