import isNil from 'lodash/isNil';
import isNull from 'lodash/isNull';
import DateMomentAdaptor from '@date-io/moment';
import unescape from 'lodash/unescape';
import { siteConfig } from '../../config/site';
import { BreadcrumbsType } from '../defs/custom';

export const generateBreadcrumbs = (crumbs: BreadcrumbsType[]): BreadcrumbsType[] => {
  return crumbs
    .filter(
      (crumb) =>
        (crumb.crumbLabel !== undefined && crumb.crumbLabel !== null) ||
        crumb.pathname !== undefined
    )
    .map((crumb) => ({ pathname: crumb.pathname, crumbLabel: crumb.crumbLabel }));
};

export const generateBreadcrumbsSchema = (breadcrumbsArray: BreadcrumbsType[]) => {
  const validBreadcrumbs = breadcrumbsArray.filter((crumb) => crumb.crumbLabel !== undefined);

  const schemaMarkup = {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: validBreadcrumbs.map((crumb, index) => ({
      '@type': 'ListItem',
      position: index + 1,
      item: {
        '@id': crumb.pathname,
        name: crumb.crumbLabel,
      },
    })),
  };

  return schemaMarkup;
};

export const setDefaultPreferences = () => {
  // Set the default lease option local storage object if it doesn't exist
  if (isNull(localStorage.getItem('content'))) {
    return localStorage.setItem(
      'content',
      JSON.stringify({
        leaseType: 'business',
        agreementTerms: 48,
        initialRental: 12,
        annualMileage: 8000,
      })
    );
  } else {
    return;
  }
};

export const numberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

// Smooth scroll to a DOM element based on the element ID
export const smoothScrollToElement = (elementId: string) => {
  const element = document.getElementById(elementId);

  if (typeof window !== 'undefined' && element) {
    setTimeout(() => {
      element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
      return;
    }, 500);
  }
};

// Parse and decode a object to remove special characters
export const decodeObjectSpecialCharacters = (str) => {
  const obj = unescape(JSON.stringify(str));

  return JSON.parse(obj);
};

// Get the current month
export const getMonth = () => {
  const moment = new DateMomentAdaptor();
  const month = moment.date().format('MMMM');
  return month;
};

// Return a date in a human readable format
export const formatDate = (date, format) => {
  const moment = new DateMomentAdaptor();
  const formattedDate = moment.date(date).format(format);

  return formattedDate;
};

export const getRelativeURL = (url: string) => {
  // remove the :// and split the string by '/'
  const arr = url.replace('://', '').split('/');

  // remove the first element (the domain part)
  arr.shift();

  // join again the split parts and return them with a '/' preceding
  return '/' + arr.join('/');
};

// Check if a number is odd or even
export const isOdd = (n) => {
  return Math.abs(n % 2) == 1;
};

export const isInternalUrl = (url) => {
  return url.includes(siteConfig.domain);
};

export function onlyUnique(value, index, self) {
  if (value !== null) {
    return self.indexOf(value) === index;
  }
}

export function removeSpace(str) {
  return str.replace(/\s/g, '');
}

// /**
//  * @function getFinalPrice
//  * @description apply VAT based on the leaseType state
//  * @param monthlyPrice - The variant monthly total price
//  * @param depositPrice - The variant deposit price
//  */
export function getFinalPrice(leaseType, monthlyPrice, depositPrice) {
  const monthlyPriceWithVat = Math.round(
    leaseType === 'personal' ? monthlyPrice * 1.2 : monthlyPrice
  );

  const despositWithVAT = Math.round(leaseType === 'personal' ? depositPrice * 1.2 : depositPrice);

  // Update the variant price
  return {
    monthlyPrice: monthlyPriceWithVat,
    depositPrice: despositWithVAT,
  };
}

/**
 * @function calculatePrice
 * @description - Update the price when changing the variant
 * @param variant - The active variant
 * @param values - The form input values
 */
export function calculatePrice(
  leaseType,
  agreementTerms,
  initialRental,
  annualMileage,
  variant,
  addonsTotal
) {
  // Check if one of the meta fields price is zero, indicating price is POA
  const isPoa = variant?.post_meta_fields?.price_24m?.mileage_8k === 0 ? true : false;

  if (!variant) {
    return;
  }

  const term = `price_${agreementTerms}m`;
  const mileage = `mileage_${annualMileage / 1000}k`;
  const priceTable = variant.post_meta_fields[term];
  const addonsFinalPrice = addonsTotal ? addonsTotal : 0;
  const startPrice = priceTable[mileage] + addonsFinalPrice;
  const monthlyPrice = startPrice * ((agreementTerms + 2) / (agreementTerms + initialRental - 1));
  const depositPrice = monthlyPrice * initialRental;

  // Return 0 if the item should be POA
  if (isPoa) {
    return {
      monthlyPrice: 0,
      depositPrice: 0,
    };
  }

  return getFinalPrice(leaseType, monthlyPrice, depositPrice);
}

/**
 * @function calculatePriceMonthly
 * @description - Return the monthly price for a variant without applying VAT
 * @param variant - The active variant
 * @param values - The form input values
 */
export function calculatePriceMonthly(
  leaseType,
  agreementTerms,
  initialRental,
  annualMileage,
  variant
) {
  // Check if all the values are zero, meaning the item is POA
  if (
    isNil(variant.post_meta_fields.price_24m) &&
    isNil(variant.post_meta_fields.price_36m) &&
    isNil(variant.post_meta_fields.price_48m)
  ) {
    return 'POA';
  }

  const term = `price_${agreementTerms}m`;
  const mileage = `mileage_${annualMileage / 1000}k`;
  const priceTable = variant.post_meta_fields[term];

  const startPrice = priceTable[mileage];

  const monthlyPrice = startPrice * ((agreementTerms + 2) / (agreementTerms + initialRental - 1));
  const depositPrice = monthlyPrice * initialRental;
  const priceWithVAT = getFinalPrice(leaseType, monthlyPrice, depositPrice);

  return priceWithVAT.monthlyPrice >= 1 ? priceWithVAT.monthlyPrice : 'POA';
}

/**
 * @function getVariantIndex
 * @description Get the index of a variant relative to the position in the variants array by filtering the array
 * @param variantId The wordpress ID of the variant
 * @param variants All the variants for the brand
 */
export function getVariantIndex(variantId, variants) {
  const variant = variants.edges.filter((variant) => variant.node.wordpress_id === variantId);

  return variant[0].node;
}

/**
 * Truncate a block of text
 * @param input {string}
 * @param length {number}
 * @returns
 */
export function truncate(input, length) {
  if (input.length > length) {
    return input.substring(0, length) + '...';
  }
  return input;
}

/**
 * Shuffle an array and return it
 * @param array
 * @returns array
 */
export function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    const temp = array[i];
    array[i] = array[j];
    array[j] = temp;
  }

  return array;
}

export default null;
