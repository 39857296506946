import isEmpty from 'lodash/isEmpty';

// Utils
import { calculatePriceMonthly } from '../utils/utils';
import { setNotification } from './notifications';
import { fuelTypesCap } from '../constants';

export const OFFERS_FILTERED = 'specialOffers/OFFERS_FILTERED';
export const OFFERS_LOADING = 'specialOffers/OFFERS_LOADING';
export const OFFERS_LOADING_POSTS = 'specialOffers/OFFERS_LOADING_POSTS';
export const OFFERS_RESET = 'specialOffers/OFFERS_RESET';
export const OFFERS_UPDATE_AGREEMENT_TERMS = 'specialOffers/BRAND_UPDATE_AGREEMENT_TERMS';
export const OFFERS_UPDATE_ANNUAL_MILEAGE = 'specialOffers/BRAND_UPDATE_ANNUAL_MILEAGE';
export const OFFERS_UPDATE_BODY_STYLES = 'specialOffers/OFFERS_UPDATE_BODY_STYLES';
export const OFFERS_UPDATE_BRANDS = 'specialOffers/OFFERS_UPDATE_BRANDS';
export const OFFERS_UPDATE_FUEL = 'specialOffers/OFFERS_UPDATE_FUEL';
export const OFFERS_UPDATE_INITIAL_RENTAL = 'specialOffers/BRAND_UPDATE_INITIAL_RENTAL';
export const OFFERS_UPDATE_INITIAL_VARIANTS = 'specialOffers/OFFERS_UPDATE_INITIAL_VARIANTS';
export const OFFERS_UPDATE_PAGE = 'specialOffers/OFFERS_UPDATE_PAGE';
export const OFFERS_UPDATE_POSTS_PER_PAGE = 'specialOffers/OFFERS_UPDATE_POSTS_PER_PAGE';
export const OFFERS_UPDATE_PREFERENCES = 'specialOffers/OFFERS_UPDATE_PREFERENCES';
export const OFFERS_UPDATE_PRICE = 'specialOffers/OFFERS_UPDATE_PRICE';
export const OFFERS_UPDATE_SORT = 'specialOffers/OFFERS_UPDATE_SORT';
export const OFFERS_UPDATE_TRANSMISSION = 'specialOffers/OFFERS_UPDATE_TRANSMISSION';
export const OFFERS_UPDATE_VARIANTS = 'specialOffers/OFFERS_UPDATE_VARIANTS';
export const OFFERS_UPDATE_LEASE_TYPE = 'specialOffers/OFFERS_UPDATE_LEASE_TYPE';
export const OFFERS_FILTER_COUNT = 'specialOffers/OFFERS_FILTER_COUNT';

export function setSort(payload) {
  return {
    payload,
    type: OFFERS_UPDATE_SORT,
  };
}

export function setPage(page, postsToShow) {
  return {
    page,
    postsToShow,
    type: OFFERS_UPDATE_PAGE,
  };
}

export function updateInitialValues(initialRental, agreementTerms, leaseType, annualMileage) {
  return {
    initialRental,
    agreementTerms,
    leaseType,
    annualMileage,
    type: OFFERS_UPDATE_PREFERENCES,
  };
}

export function updateInitialRental(payload) {
  return {
    payload,
    type: OFFERS_UPDATE_INITIAL_RENTAL,
  };
}

export function setFuelType(payload) {
  return {
    payload,
    type: OFFERS_UPDATE_FUEL,
  };
}

export function updateAnnualMileage(payload) {
  return {
    payload,
    type: OFFERS_UPDATE_ANNUAL_MILEAGE,
  };
}

export function setTerms(payload) {
  return {
    payload,
    type: OFFERS_UPDATE_AGREEMENT_TERMS,
  };
}

export function reset() {
  return {
    type: OFFERS_RESET,
  };
}

export function setLoading(payload) {
  return {
    payload,
    type: OFFERS_LOADING,
  };
}

export function setVariants(payload) {
  return {
    payload,
    type: OFFERS_UPDATE_VARIANTS,
  };
}

export function setBrands(payload) {
  return {
    payload,
    type: OFFERS_UPDATE_BRANDS,
  };
}

export function setInitialVariants(payload) {
  return {
    payload,
    type: OFFERS_UPDATE_INITIAL_VARIANTS,
  };
}

export function setBodyStyles(payload) {
  return {
    payload,
    type: OFFERS_UPDATE_BODY_STYLES,
  };
}

export function setTransmission(payload) {
  return {
    payload,
    type: OFFERS_UPDATE_TRANSMISSION,
  };
}

export function setPrice(payload) {
  return {
    payload,
    type: OFFERS_UPDATE_PRICE,
  };
}

export function setPostsLoading(payload) {
  return {
    payload,
    type: OFFERS_LOADING_POSTS,
  };
}

export function setIsFiltered(payload) {
  return {
    payload,
    type: OFFERS_FILTERED,
  };
}

export function setPostsPerPage(payload) {
  return {
    payload,
    type: OFFERS_UPDATE_POSTS_PER_PAGE,
  };
}

export function setLeaseType(payload) {
  return {
    payload,
    type: OFFERS_UPDATE_LEASE_TYPE,
  };
}

export function setFilterCount(payload) {
  return {
    payload,
    type: OFFERS_FILTER_COUNT,
  };
}

export const handleUpdatePostsPerPage = (perPage, page) => (dispatch) => {
  dispatch(setPage(page, page * perPage));
  dispatch(setPostsPerPage(perPage));
};

export const handleReset = () => (dispatch) => {
  dispatch(reset());
};

export const handleUpdatePage = (page, postsPerPage) => (dispatch) => {
  dispatch(setPostsLoading(true));
  dispatch(setPage(page + 1, (page + 1) * postsPerPage));

  dispatch(setPostsLoading(false));
};

export const handleFilterCount = (count) => (dispatch) => {
  dispatch(setFilterCount(count));
};

export const handleFilterVariants = (
  IsNotification,
  leaseType,
  agreementTerms,
  initialRental,
  annualMileage,
  initialVariants,
  variants,
  fuelType,
  brands,
  bodyStyles,
  transmission,
  price,
  sortValue,
  postsPerPage,
  isCount
) => (dispatch) => {
  const scrollHeader = document.getElementById('search-header');
  // Start with the initial variants and filter down from there
  let variants = initialVariants;

  // Convert the value to the CAP code for the specific transmission type to query against
  const transmissionCapCode = transmission === 'Automatic' ? 'A' : 'M';

  const selectedbrands = isEmpty(brands) ? [] : brands.map((item) => item.value);
  const selectedBodyStyles = isEmpty(bodyStyles) ? [] : bodyStyles.map((item) => item.value);

  // Brands
  if (!isEmpty(selectedbrands)) {
    variants = variants.filter(
      (item) =>
        item.node &&
        item.node.post_meta_fields &&
        item.node.post_meta_fields.brands &&
        item.node.post_meta_fields.brands[0] &&
        item.node.post_meta_fields.brands[0].slug &&
        selectedbrands.indexOf(item.node.post_meta_fields.brands[0].slug) > -1
    );
  }

  // Fuel
  if (fuelType !== 'All') {
    // Get the Cap code value for the selected fuel type
    const fuelCode = fuelTypesCap.find((f) => f.label === fuelType)?.value;

    variants = variants.filter(
      (item) =>
        item.node &&
        item.node.post_meta_fields &&
        item.node.post_meta_fields.capcode &&
        item.node.post_meta_fields.capcode.charAt(11) === fuelCode
    );
  }

  // Body Style
  if (!isEmpty(selectedBodyStyles)) {
    variants = variants.filter(
      (item) =>
        item.node &&
        item.node.post_meta_fields &&
        selectedBodyStyles.includes(item.node.post_meta_fields.body_type)
    );
  }

  // Transmission
  if (transmission !== 'All') {
    variants = variants.filter(
      (item) =>
        item.node &&
        item.node.post_meta_fields &&
        item.node.post_meta_fields.capcode &&
        item.node.post_meta_fields.capcode.charAt(13) === transmissionCapCode
    );
  }

  // Price
  variants = variants.filter((variant) => {
    const variantPrice = calculatePriceMonthly(
      leaseType,
      agreementTerms,
      initialRental,
      annualMileage,
      variant.node
    );

    // Don't filter anything if no filter has been set
    if (price[0] === 150 && price[1] === 1500) {
      return variantPrice;
    }

    // Filter the price from 0 if the min is the lowest value
    if (price[0] === 1200) {
      return variantPrice >= 0 && variantPrice <= price[1];
    }

    // Remove the filter from the highest value if the max value is set
    if (price[1] === 1200) {
      return variantPrice >= price[0];
    }

    // Filter the price between the two values
    return variantPrice >= price[0] && variantPrice <= price[1];
  });

  // Sort the results regardless of sort options
  if (sortValue === 'Ascending') {
    variants = variants.sort(
      (a, b) =>
        a.node &&
        a.node.post_meta_fields &&
        a.node.post_meta_fields.brand_title &&
        a.node.post_meta_fields.brand_title.localeCompare(b.node.post_meta_fields.brand_title)
    );
  }

  if (sortValue === 'Descending') {
    variants = variants.reverse(
      (a, b) =>
        a.node &&
        a.node.post_meta_fields &&
        a.node.post_meta_fields.brand_title &&
        a.node.post_meta_fields.brand_title.localeCompare(b.node.post_meta_fields.brand_title)
    );
  }

  if (sortValue === 'Price: Highest' || sortValue === 'Price: Lowest') {
    const variantsWithPrice = variants.map((variant) => {
      const variantPrice = calculatePriceMonthly(
        leaseType,
        agreementTerms,
        initialRental,
        annualMileage,
        variant.node
      );

      return {
        ...variant,
        variantPrice,
      };
    });

    if (sortValue === 'Price: Highest') {
      variants = variantsWithPrice.sort((a, b) => b.variantPrice - a.variantPrice);
    } else {
      variants = variantsWithPrice.sort((a, b) => a.variantPrice - b.variantPrice);
    }
  }

  if (isCount) {
    return dispatch(setFilterCount(parseInt(variants.length)));
  }

  // Update the values to match values coming in from query params.
  dispatch(setFilterCount(parseInt(variants.length)));
  dispatch(setLeaseType(leaseType));
  dispatch(setBodyStyles(bodyStyles));
  dispatch(setBrands(brands));
  dispatch(setFuelType(fuelType));
  dispatch(setTransmission(transmission));
  dispatch(setPrice(price));
  dispatch(updateInitialRental(initialRental));
  dispatch(setTerms(agreementTerms));
  dispatch(updateAnnualMileage(annualMileage));

  dispatch(setVariants(variants));
  dispatch(setIsFiltered(true));

  // Reset the pagination
  dispatch(setPage(1, postsPerPage));

  if (IsNotification) {
    // Scroll to the search header
    typeof window !== 'undefined' &&
      scrollHeader?.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });

    dispatch(setNotification(true, `${variants.length} results found`));
  }

  // Save preferences to local storage to persist on page change
  localStorage.setItem(
    'content',
    JSON.stringify({ leaseType, agreementTerms, initialRental, annualMileage })
  );
};

export const handleUpdateSort = (payload) => (dispatch) => {
  dispatch(setSort(payload));
};

export const handleInitialVariants = (variants) => (dispatch) => {
  dispatch(setVariants(variants));
  dispatch(setInitialVariants(variants));
};

export const handleGetInitialPreferences = () => (dispatch) => {
  const savedPreferences =
    typeof window !== 'undefined' &&
    localStorage.getItem('content') &&
    JSON.parse((typeof window !== 'undefined' && localStorage.getItem('content')) || '');

  if (!savedPreferences) {
    dispatch(updateInitialValues(12, 48, 'business', 8000));
  } else {
    dispatch(
      updateInitialValues(
        savedPreferences.initialRental,
        savedPreferences.agreementTerms,
        savedPreferences.leaseType,
        savedPreferences.annualMileage
      )
    );
  }

  dispatch(setLoading(false));
};

export const handleUpdateTerms = (leaseType, agreementTerms, initialRental, annualMileage) => (
  dispatch
) => {
  // Save preferences to local storage to persist on page change
  localStorage.setItem(
    'content',
    JSON.stringify({ leaseType, agreementTerms, initialRental, annualMileage })
  );

  dispatch(setTerms(agreementTerms));
};

export const handleUpdateFuel = (payload) => (dispatch) => {
  dispatch(setFuelType(payload));
};

export const handleUpdateTransmission = (payload) => (dispatch) => {
  dispatch(setTransmission(payload));
};

export const handleUpdatePrice = (payload) => (dispatch) => {
  dispatch(setPrice(payload ? payload : []));
};

export const handleUpdateBrands = (payload) => (dispatch) => {
  dispatch(setBrands(payload ? payload : []));
};

export const handleUpdateBodyStyle = (payload) => (dispatch) => {
  dispatch(setBodyStyles(payload));
};

export const handleUpdateLeaseType = (payload) => (dispatch) => {
  dispatch(setLeaseType(payload));
};

export const handleUpdateInitialRental = (
  leaseType,
  agreementTerms,
  initialRental,
  annualMileage
) => (dispatch) => {
  // Save preferences to local storage to persist on page change
  localStorage.setItem(
    'content',
    JSON.stringify({ leaseType, agreementTerms, initialRental, annualMileage })
  );

  dispatch(updateInitialRental(initialRental));
};

export const handleUpdateAnnualMileage = (
  leaseType,
  agreementTerms,
  initialRental,
  annualMileage
) => (dispatch) => {
  // Save preferences to local storage to persist on page change
  localStorage.setItem(
    'content',
    JSON.stringify({ leaseType, agreementTerms, initialRental, annualMileage })
  );

  dispatch(updateAnnualMileage(annualMileage));
};
